import { buildQuery } from 'ra-data-graphql-simple';
import { FetchType } from 'utils';

import staticQuestion from './staticQuestion';
import organisations from './organisations';
import users from './users';

const myBuildQuery = (introspection: any) => (fetchType: FetchType, resource: string, params: string) => {
  const builtQuery = buildQuery(introspection)(fetchType, resource, params);
  switch (resource) {
    case 'User':
      return users(fetchType, builtQuery);
    case 'Organisation':
      return organisations(fetchType, builtQuery);
    case 'StaticQuestion':
      return staticQuestion(fetchType, builtQuery);
    default:
      return builtQuery;
  }
};

export default myBuildQuery;
