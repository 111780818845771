import { opacify } from 'polished';

export const colors = {
  BLACK: '#000000',
  BLUE: '#183766',
  DAMPENED_BLUE: opacify(-0.7, '#1B4B96'),
  GREEN: '#5CB85C',
  INFO_BLUE: '#1A76D2',
  LIGHT_BLUE: '#1B4B96',
  NAV_BLUE: '#374F72',
  ORANGE: '#d9a022',
  PAPER: '#F4F7FB',
  PASTEL_BLUE: '#A6DAEF',
  PASTEL_GREEN: '#BFE2CA',
  PASTEL_LIGHT_BLUE: '#D0E2EC',
  PASTEL_RED: '#F4858E',
  PINK: '#F474A5',
  RED: '#D9534F',
  WHITE: '#FFFFFF',
  YELLOW: '#FFC107',
};

export default colors;
