import gql from 'graphql-tag';
import { FetchType } from 'utils';

const StaticQuestionFields = gql`
  fragment StaticQuestionFields on StaticQuestion {
    id
    version
    name
    hint
    level
    marks
    calculator
    created_at
    updated_at
    parts {
      id
      question
      working
      marks
      answers {
        id
        answer
        correct
        __typename
      }
      __typename
    }
    __typename
  }
`;

const OrganisationQuery = (fetchType: FetchType, builtQuery: any) => {
  switch (fetchType) {
    case 'GET_ONE':
      return {
        ...builtQuery,
        query: gql`
          query StaticQuestion($id: String) {
            data: StaticQuestion(id: $id) {
              ...StaticQuestionFields
            }
          }
          ${StaticQuestionFields}
        `,
      };
    default:
      return builtQuery;
  }
};

export default OrganisationQuery;
