import ApolloClient from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { getBackendUrl } from 'utils';

const cache = new InMemoryCache();

export const client = new ApolloClient({
  cache,
  name: 'web',
  request: async operation => {
    operation.setContext({
      headers: {
        authorization: window.localStorage.getItem('token') || '',
      },
    });
  },
  uri: getBackendUrl(),
  version: '2.0',
});
