import * as React from 'react';
import { ReferenceArrayInput, AutocompleteArrayInput, Edit, SimpleForm, TextInput, NumberInput } from 'react-admin';

const DebugArrayInput = (props: any) => (
  <AutocompleteArrayInput
    {...{
      ...props,
      input: {
        ...props.input,
        value: props.input.value.map((value: any) => value.id ?? value),
      },
    }}
  />
);

export const edit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <NumberInput source="level" />
      <NumberInput source="marks" />
      <ReferenceArrayInput source="static_questions" reference="StaticQuestion" label="Static Questions">
        <DebugArrayInput />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="syllabus_paths" reference="SyllabusPath" label="Static Paths">
        <DebugArrayInput />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);
