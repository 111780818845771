import { uniqueId } from 'lodash';
import * as React from 'react';
import styled, { css } from 'styled-components';
import Latex from './Latex';
import LatexTable from './LatexTable';
import colors from './colors';

const Text = styled.div<{ thin?: boolean }>`
  text-align: left;
  line-height: 1.8;
  opacity: 0.9;
  color: ${colors.BLUE};
  ${props =>
    props.thin &&
    css`
      font-weight: 200;
      font-size: 15px;
    `}
`;
const Paragraph = styled.div`
  text-align: left;
  opacity: 0.9;
  padding-top: 15px;
`;

const Maths = styled.div`
  overflow-x: auto;
  text-align: center;
  padding-top: 13px;
  padding-bottom: 13px;
  ${(props: { equation?: boolean }) =>
    props.equation &&
    css`
      text-align: left;
      margin-left: 20px;
    `};
`;

export const removePlottingIncompatabilities = (value: string) => {
  // removes fractions
  let s = value.replace(/\\frac\{([^}]+)\}\{([^}]+)\}/g, (_, g1, g2) => `(${g1})/(${g2})`);
  s = s.replace(/\\(bigg|Bigg|big|Big)([^.]{1})/g, (_, __, g2) => g2);
  return s;
};

interface PropTypes {
  label: string;
  thin?: boolean;
  className?: string;
}
interface LateXType {
  hasBeenClassified?: boolean;
  item: string;
  type: string;
  key: string;
}
export default class Latexify extends React.Component<PropTypes> {
  public classifyForNewline = (working: string, type: string) => {
    const arrayOfNewLines = working.split('NEWLINE');
    const arrayOfNewLineObjects = arrayOfNewLines.map(item => ({
      hasBeenClassified: false,
      item,
      key: uniqueId(),
      type,
    }));
    return arrayOfNewLineObjects;
  };

  public splitAndClassify = (arrayToClassify: LateXType[], stringToSplitBy: string, type: string) => {
    const classifiedObjectsArray: LateXType[] = [];
    arrayToClassify.forEach(object => {
      if (object.hasBeenClassified) {
        classifiedObjectsArray.push(object);
      } else {
        const arrayOfSplittedLines = object.item.split(stringToSplitBy);
        arrayOfSplittedLines.forEach((item, index) => {
          if (index === 0) {
            classifiedObjectsArray.push({
              hasBeenClassified: false,
              item,
              key: uniqueId(),
              type: 'text',
            });
          } else {
            classifiedObjectsArray.push({
              hasBeenClassified: true,
              item,
              key: uniqueId(),
              type,
            });
          }
        });
      }
    });
    return classifiedObjectsArray;
  };

  public WrapObjectsInLatex = (value: string) => {
    let classifiedArray;
    classifiedArray = this.classifyForNewline(value, 'text');
    classifiedArray = this.splitAndClassify(classifiedArray, 'MATHSLINE', 'maths');
    classifiedArray = this.splitAndClassify(classifiedArray, 'EQUATIONS', 'equations');
    classifiedArray = this.splitAndClassify(classifiedArray, 'PARAGRAPH', 'paragraph');
    classifiedArray = this.splitAndClassify(classifiedArray, 'VIDEO', 'video');
    classifiedArray = this.splitAndClassify(classifiedArray, 'FUNCTIONPLOT', 'functionPlot');
    classifiedArray = this.splitAndClassify(classifiedArray, 'PLOT', 'plot');
    classifiedArray = this.splitAndClassify(classifiedArray, 'TABLE', 'table');
    classifiedArray = this.splitAndClassify(classifiedArray, 'SVG', 'svg');

    const wrappedLatexArray = classifiedArray.map(object => {
      switch (object.type) {
        case 'maths': {
          return (
            <Maths key={object.key}>
              <Latex>{object.item}</Latex>
            </Maths>
          );
        }
        case 'paragraph': {
          return (
            <Paragraph key={object.key}>
              <Latex>{object.item}</Latex>
            </Paragraph>
          );
        }
        case 'equations': {
          return (
            <Maths equation key={`equation ${object.key} ${object.item}`}>
              <Latex>{object.item}</Latex>
            </Maths>
          );
        }
        case 'table': {
          const data = JSON.parse(object.item);
          return <LatexTable data={data} />;
        }
        default:
          return (
            <Text key={object.key} thin={this.props.thin} className={this.props.className}>
              <Latex>{object.item}</Latex>
            </Text>
          );
      }
    });
    return wrappedLatexArray;
  };

  public render() {
    return <>{this.WrapObjectsInLatex(this.props.label)}</>;
  }
}
