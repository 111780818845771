import { createMuiTheme } from '@material-ui/core/styles';
import buildGraphQLProvider from 'ra-data-graphql-simple';
import React, { useEffect, useState } from 'react';
import { Admin, Resource } from 'react-admin';

import { client } from '../apollo';
import { authProvider } from '../utils';
import buildQuery from './buildQuery';
import * as blogs from './models/blogs';
import * as examboards from './models/examboards';
import * as featureFlags from './models/featureFlags';
import * as organisations from './models/organisations';
import * as users from './models/users';
import * as emails from './models/emails';
import * as staticQuestion from './models/staticQuestion';
import * as semiDynamicShells from './models/semiDynamicShells';

const theme = createMuiTheme({
  palette: {
    error: {
      main: '#D9534F',
    },
    primary: {
      main: '#374F72',
    },
    secondary: {
      main: '#183766',
    },
  },
  typography: {
    fontFamily: ['proxima-nova', 'sans-serif'].join(','),
  },
});

export const App = () => {
  const [dataProvider, setDataProvider] = useState(null);

  useEffect(() => {
    (async () => {
      const provider = await buildGraphQLProvider({
        buildQuery,
        client,
      });
      setDataProvider(() => provider);
    })();
  }, []);

  if (!dataProvider) {
    return <div>Loading</div>;
  }
  return (
    <Admin theme={theme} dataProvider={dataProvider} authProvider={authProvider}>
      {(permissions: string) => [
        permissions === 'admin' ? <Resource name="Blog" {...blogs} /> : null,
        permissions === 'admin' ? <Resource name="Examboard" {...examboards} /> : null,
        permissions === 'admin' ? <Resource name="Email" {...emails} /> : null,
        permissions === 'admin' ? <Resource name="FeatureFlag" options={{ label: 'Features' }} {...featureFlags} /> : null,
        permissions === 'admin' ? <Resource name="Organisation" {...organisations} /> : null,
        <Resource name="SemiDynamicShell" options={{ label: 'Semi Dynamic Shells' }} {...semiDynamicShells} />,
        <Resource name="StaticQuestion" options={{ label: 'Static Questions' }} {...staticQuestion} />,
        <Resource name="SyllabusPath" />,
        permissions === 'admin' ? <Resource name="User" {...users} /> : null,
      ]}
    </Admin>
  );
};
