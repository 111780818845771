import ReceiptIcon from '@material-ui/icons/Receipt';
import MarkdownInput from 'ra-input-markdown';
import * as React from 'react';
import {
  Create,
  Datagrid,
  Edit,
  ImageField,
  List,
  SelectField,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from 'react-admin';

export const icon = ReceiptIcon;
export const list = (props: any) => {
  return (
    <List {...props} bulkActionButtons={false}>
      <Datagrid rowClick="edit">
        <TextField source="title" />
        <TextField source="status" />
      </Datagrid>
    </List>
  );
};

export const create = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <TextInput source="header" defaultValue="https://assets.aitutor.co.uk/" />
      <TextInput source="short" multiline />
      <MarkdownInput source="markdown" />
      <SelectInput
        source="status"
        choices={[
          { id: 'DRAFT', name: 'Draft' },
          { id: 'ACTIVE', name: 'Active' },
        ]}
        defaultValue="DRAFT"
      />
    </SimpleForm>
  </Create>
);

export const edit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput disabled source="slug" />
      <TextInput source="title" />
      <TextInput source="short" multiline />
      <TextInput source="header" />
      <MarkdownInput source="markdown" />
      <SelectInput
        source="status"
        choices={[
          { id: 'DRAFT', name: 'Draft' },
          { id: 'ACTIVE', name: 'Active' },
        ]}
      />
    </SimpleForm>
  </Edit>
);

export const show = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="slug" />
      <TextField source="title" />
      <TextField source="short" />
      <ImageField source="header" />
      <TextField source="markdown" />
      <SelectField
        source="status"
        choices={[
          { id: 'DRAFT', name: 'Draft' },
          { id: 'ACTIVE', name: 'Active' },
        ]}
      />
    </SimpleShowLayout>
  </Show>
);
