import Button from '@material-ui/core/Button';
import * as React from 'react';
import { Edit, ReferenceInput, SelectInput, showNotification, SimpleForm, TextInput, ShowButton, TopToolbar } from 'react-admin';

import { connect } from 'react-redux';
import { getBackendUrl } from 'utils';

const PostEditActions = connect(null, { showNotification })(({ basePath, data, showNotification: show }: any) => {
  const upgradable = data && (!data.plan || data.plan === 'basic');

  const giveScholarship = () =>
    fetch(getBackendUrl(), {
      body: JSON.stringify({
        operationName: 'GiveUserScholarship',
        query: 'mutation GiveUserScholarship($uuid: String!) {\n  giveScholarship(uuid: $uuid) \n}',
        variables: {
          uuid: data?.id,
        },
      }),
      headers: new Headers({ authorization: window.localStorage.getItem('token') ?? '', 'Content-Type': 'application/json' }),
      method: 'POST',
    })
      .then(() => {
        show('Scholarship given');
      })
      .catch(() => {
        show('Error: scholarship failed', 'warning');
      });

  return (
    <TopToolbar>
      {upgradable ? (
        <Button color="primary" onClick={() => giveScholarship()}>
          Give Scholarship
        </Button>
      ) : (
        <div />
      )}
      <ShowButton basePath={basePath} record={data} />
    </TopToolbar>
  );
});

export const edit = (props: any) => (
  <Edit {...props} actions={<PostEditActions />}>
    <SimpleForm>
      <TextInput source="title" />
      <TextInput source="firstname" />
      <TextInput source="lastname" />
      <TextInput source="email" />
      <SelectInput
        source="examboard"
        choices={[
          { id: 'AQA', name: 'AQA' },
          { id: 'EDEXCEL', name: 'EdExcel' },
          { id: 'OCR', name: 'OCR' },
        ]}
      />
      <SelectInput
        source="type"
        choices={[
          { id: 'student', name: 'Student' },
          { id: 'teacher', name: 'Teacher' },
          { id: 'admin', name: 'Admin' },
        ]}
      />
      <SelectInput
        source="plan"
        choices={[
          { id: 'basic', name: 'Basic' },
          { id: 'schoolBasic', name: 'School Basic' },
          { id: 'schoolPremium', name: 'School Premium' },
          { id: 'premiumMonthly', name: 'Premium Monthly' },
          { id: 'premiumYearly', name: 'Premium Yearly' },
          { id: 'premiumYearly2', name: 'Premium Yearly' },
          { id: 'premiumBiyearly', name: 'Premium Bi-yearly' },
          { id: 'premiumBiyearly2', name: 'Premium Bi-yearly' },
        ]}
      />
      <ReferenceInput label="Organisation" source="organisation.id" reference="Organisation" disabled>
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
