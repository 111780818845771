import * as React from 'react';
import { BooleanField, Show, SimpleShowLayout, TextField } from 'react-admin';

export const show = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="version" />
      <TextField source="name" />
      <TextField source="hint" />
      <TextField source="level" />
      <TextField source="marks" />
      <BooleanField source="calculator" />
    </SimpleShowLayout>
  </Show>
);
