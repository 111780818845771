import * as React from 'react';
import { Datagrid, List, TextField } from 'react-admin';

export const list = (props: any) => {
  return (
    <List {...props} bulkActionButtons={false}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
      </Datagrid>
    </List>
  );
};
