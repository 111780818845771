import FlagIcon from '@material-ui/icons/Flag';
import * as React from 'react';
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  List,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from 'react-admin';

export const icon = FlagIcon;
export const list = (props: any) => {
  return (
    <List {...props} bulkActionButtons={false}>
      <Datagrid rowClick="edit">
        <TextField source="title" />
        <BooleanField source="enabled" />
      </Datagrid>
    </List>
  );
};

export const create = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <BooleanInput source="enabled" />
    </SimpleForm>
  </Create>
);

export const edit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput source="title" />
      <BooleanInput source="enabled" />
    </SimpleForm>
  </Edit>
);

export const show = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="title" />
      <BooleanField source="enabled" />
    </SimpleShowLayout>
  </Show>
);
