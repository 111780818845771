import * as React from 'react';
import { FunctionField, Datagrid, Filter, List, TextField, TextInput } from 'react-admin';
import moment from 'moment';

const CustomFilter = (props: any) => (
  <Filter {...props}>
    <TextInput source="to" />
  </Filter>
);

export const list = (props: any) => {
  return (
    <List {...props} bulkActionButtons={false} filters={<CustomFilter />}>
      <Datagrid rowClick="edit">
        <TextField source="to" />
        <TextField source="subject" />
        <FunctionField
          label="Sent"
          source="created_at"
          render={(record: any) => `${moment(Number(record.created_at)).format('HH:mm dddd Do MMMM')}`}
        />
      </Datagrid>
    </List>
  );
};
