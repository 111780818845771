import RestorePageIcon from '@material-ui/icons/RestorePage';
import * as React from 'react';
import { Datagrid, List, TextField } from 'react-admin';

export const icon = RestorePageIcon;
export const list = (props: any) => {
  return (
    <List {...props} bulkActionButtons={false}>
      <Datagrid>
        <TextField source="board" />
      </Datagrid>
    </List>
  );
};
