import * as React from 'react';
import { useFormState } from 'react-final-form';
import styled from 'styled-components';
import { ClassroomCard } from './copied/ClassroomCard';

interface Props {
  source: string;
}

interface Answer {
  answer: string;
  correct: boolean;
  selected: boolean;
  chosen: boolean;
}

const genQuestions = (parts: any[] | undefined) => {
  if (typeof parts === 'undefined' || !parts.length) {
    return [
      {
        id: '0',
        question: '',
        working: '',
        marks: 1,
        expanded: true,
        rating: 5,
        answers: [],
      },
    ];
  }
  const questions = parts.map(value => {
    return {
      question: value?.question || '',
      working: value?.working || '',
      marks: value?.marks || 1,
      expanded: true,
      rating: 5,
      answers: value?.answers?.length
        ? value.answers.map((answer: Answer) => ({
            text: answer?.answer || '',
            correct: answer?.correct || false,
            selected: false,
            chosen: false,
          }))
        : [],
    };
  });
  return questions;
};

export const Preview = (props: Props) => {
  const { values } = useFormState(props);
  const questions = genQuestions(values.parts).map((value, index) => <ClassroomCard question={value} questionIndex={index} />);
  return <Container>{questions}</Container>;
};

const Container = styled.div`
  width: 100%;
`;
