import * as React from 'react';
import { ReferenceField, SelectField, Show, SimpleShowLayout, TextField } from 'react-admin';

export const show = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="title" />
      <TextField source="firstname" />
      <TextField source="lastname" />
      <TextField source="lastname" />
      <TextField source="email" />
      <SelectField
        source="examboard"
        choices={[
          { id: 'AQA', name: 'AQA' },
          { id: 'EdExcel', name: 'EdExcel' },
          { id: 'OCR', name: 'OCR' },
        ]}
      />
      <SelectField
        source="type"
        choices={[
          { id: 'student', name: 'Student' },
          { id: 'teacher', name: 'Teacher' },
          { id: 'admin', name: 'Admin' },
        ]}
      />
      <SelectField
        source="plan"
        choices={[
          { id: 'basic', name: 'Basic' },
          { id: 'schoolBasic', name: 'School Basic' },
          { id: 'schoolPremium', name: 'School Premium' },
          { id: 'premiumMonthly', name: 'Premium Monthly' },
          { id: 'premiumYearly', name: 'Premium Yearly' },
          { id: 'premiumYearly2', name: 'Premium Yearly' },
          { id: 'premiumBiyearly', name: 'Premium Bi-yearly' },
          { id: 'premiumBiyearly2', name: 'Premium Bi-yearly' },
        ]}
      />
      <ReferenceField label="Organisation" source="organisation.id" reference="Organisation">
        <SelectField optionText="name" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);
