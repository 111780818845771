import * as React from 'react';
import { ReferenceArrayInput, AutocompleteArrayInput, Create, SimpleForm, TextInput, NumberInput } from 'react-admin';

export const create = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <NumberInput source="level" />
      <NumberInput source="marks" />
      <ReferenceArrayInput source="static_questions" reference="StaticQuestion" label="Static Questions">
        <AutocompleteArrayInput />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="syllabus_paths" reference="SyllabusPath" label="Static Paths">
        <AutocompleteArrayInput />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);
