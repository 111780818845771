import Button from '@material-ui/core/Button';
import * as React from 'react';
import {
  Datagrid,
  Filter,
  List,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
  showNotification,
  CreateButton,
  ExportButton,
  sanitizeListRestProps,
} from 'react-admin';
import { cloneElement } from 'react';
import { useDropzone } from 'react-dropzone';
import csv from 'csv-parse/lib/sync';
import { getBackendUrl } from 'utils';
import { connect } from 'react-redux';

const UserFilter = (props: any) => (
  <Filter {...props}>
    <TextInput source="email" />
  </Filter>
);

const UploadCSV = connect(null, { showNotification })(({ showNotification: show }: any) => {
  const giveMultiScholarships = (emails: string[]) =>
    fetch(getBackendUrl(), {
      body: JSON.stringify({
        operationName: 'GiveMultieUserScholarship',
        query: 'mutation GiveMultieUserScholarship($emails: [String!]!) {\n  giveMultiScholarships(emails: $emails) \n}',
        variables: {
          emails,
        },
      }),
      headers: new Headers({ authorization: window.localStorage.getItem('token') ?? '', 'Content-Type': 'application/json' }),
      method: 'POST',
    })
      .then(() => {
        show('Scholarships given');
      })
      .catch(() => {
        show('Error: scholarship failed', 'warning');
      });
  const onDrop = React.useCallback(acceptedFiles => {
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        try {
          const contents = csv(reader.result, {
            columns: true,
            skip_empty_lines: true,
            trim: true,
          });
          contents.map((row: any) => {
            const keys = Object.keys(row);
            if (!keys.includes('Email') || !keys.includes('Access Project?')) {
              show('Error: Invalid CSV Format', 'warning');
            }
            return row;
          });
          const validScholarships = contents
            .filter((user: any) => user.Email && user['Access Project?'] === 'y')
            .map((user: any) => user.Email);
          giveMultiScholarships(validScholarships);
        } catch (error) {
          show(`Error: ${error.message}`, 'warning');
        }
      }
    };
    if (acceptedFiles.length !== 1) {
      show('Error: Invalid File Upload', 'warning');
    } else {
      reader.readAsBinaryString(acceptedFiles[0]);
    }
  }, []); // eslint-disable-line
  const { getRootProps, getInputProps } = useDropzone({
    accept: '.csv',
    onDrop,
  });
  const rootProps = getRootProps() as React.HTMLAttributes<HTMLDivElement>;
  const inputProps = getInputProps() as React.HTMLAttributes<HTMLInputElement>;
  return (
    <Button>
      <div {...rootProps}>
        <input {...inputProps} />
        Upload Scholarship CSV
      </div>
    </Button>
  );
});

const ListActions = (props: any) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { currentSort, resource, displayedFilters, filterValues, basePath, showFilter, total } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton disabled={total === 0} resource={resource} sort={currentSort} filterValues={filterValues} maxResults={maxResults} />
      <UploadCSV />
    </TopToolbar>
  );
};

export const list = (props: any) => {
  return (
    <List {...props} bulkActionButtons={false} actions={<ListActions />} filters={<UserFilter />}>
      <Datagrid rowClick="edit">
        <TextField source="email" />
        <TextField source="firstname" />
        <TextField source="lastname" />
        <TextField label="School" source="organisation.name" />
      </Datagrid>
    </List>
  );
};
