import 'katex/dist/katex.css';
import { renderToString } from 'katex';
import * as React from 'react';
import styled from 'styled-components';
// import { bugsnagClient } from './bugsnag';

interface Results {
  string: string;
  type: 'text' | 'latex';
}

declare global {
  interface Window {
    MSStream: any;
    safari: any;
    HTMLElement: any;
  }
}

declare const safari: { pushNotification: any };

const latexString = (value: string): string => {
  // Remove potential HTML
  const values: string = value.replace(/(<([^>]+)>)/gi, '');
  const regularExpression: RegExp = /\$\$[\s\S]+?\$\$|\$[\s\S]+?\$/g;

  const stripDollars = (stringToStrip: string): string => {
    let stripped: string;
    if (stringToStrip[1] === '$') {
      stripped = stringToStrip.slice(2, -2);
    } else {
      stripped = stringToStrip.slice(1, -1);
    }

    return stripped;
  };

  const renderLatexString = (s: string): string => {
    let renderedString: string;
    try {
      renderedString = renderToString(s);
    } catch (error) {
      // bugsnagClient.notify(error, { severity: 'warning' });
      return s;
    }
    return renderedString;
  };

  const result: Results[] = [];

  const latexMatch = values.match(regularExpression);
  const stringWithoutLatex = values.split(regularExpression);

  if (latexMatch) {
    stringWithoutLatex.forEach((s, index) => {
      result.push({
        string: s,
        type: 'text',
      });
      if (latexMatch[index]) {
        result.push({
          string: stripDollars(latexMatch[index]),
          type: 'latex',
        });
      }
    });
  } else {
    result.push({
      string: values,
      type: 'text',
    });
  }

  const processResult = (resultToProcess: Results[]): string => {
    return resultToProcess
      .map(r => {
        if (r.type === 'text') {
          return r.string;
        }
        return renderLatexString(r.string);
      })
      .join(' ');
  };

  return processResult(result);
};

interface PropTypes {
  children: string;
  className?: string;
  svg?: boolean;
}

// For Latex labels on Safari or iOS browsers
const StyledSpan = styled.span<{ svg?: boolean }>`
  position: ${props => (props.svg ? 'fixed' : 'relative')};
`;

const Latex = (props: PropTypes) => {
  const isSafari =
    /constructor/i.test(global?.window?.HTMLElement) ||
    (p => {
      return p.toString() === '[object SafariRemoteNotification]';
    })(!global?.window?.safari || (typeof safari !== 'undefined' && safari.pushNotification));

  const isIOS = /iPad|iPhone|iPod/.test(global?.navigator?.userAgent) && !global?.window?.MSStream;
  return isIOS || isSafari ? (
    <StyledSpan
      style={{ fontFamily: 'proxima-nova,sans-serif' }}
      className={props.className}
      dangerouslySetInnerHTML={{
        __html: latexString(props.children),
      }}
      svg={props.svg}
    />
  ) : (
    <span
      style={{ position: 'relative', fontFamily: 'proxima-nova,sans-serif' }}
      className={props.className}
      dangerouslySetInnerHTML={{
        __html: latexString(props.children),
      }}
    />
  );
};

export default Latex;
