import * as React from 'react';
import { ArrayInput, SimpleFormIterator, Edit, TabbedForm, FormTab, BooleanInput, TextInput, NumberInput } from 'react-admin';
import styled from 'styled-components';
import { Preview } from './preview';

export const edit = (props: any) => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab label="Core">
        <TextInput source="name" />
        <TextInput source="hint" />
        <NumberInput source="level" />
        <NumberInput source="marks" />
        <BooleanInput source="calculator" />
      </FormTab>
      <FormTab label="Parts">
        <Container>
          <ArrayInput source="parts">
            <SimpleFormIterator>
              <TextInput multiline fullWidth source="question" label="Question" />
              <TextInput multiline fullWidth source="working" label="Working" />
              <NumberInput source="marks" label="Marks" />
              <ArrayInput source="answers" label="Answers">
                <SimpleFormIterator>
                  <TextInput multiline fullWidth source="answer" label="Answer" />
                  <BooleanInput source="correct" label="Correct" />
                </SimpleFormIterator>
              </ArrayInput>
            </SimpleFormIterator>
          </ArrayInput>
          <Preview source="parts" />
        </Container>
      </FormTab>
    </TabbedForm>
  </Edit>
);

const Container = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
`;
