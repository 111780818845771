import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import Latex from './Latex';

interface PropTypes {
  data: {
    columns?: string[][];
    header: string[];
    rows: string[][];
    type: string;
  };
}
class LatexTable extends React.Component<PropTypes> {
  public tableHeader = () => {
    if (this.props.data.header) {
      return (
        <TableHead>
          <TableRow>
            {this.props.data.header.map(val => (
              <TableCell>
                <b>
                  <Latex>{`${val}`}</Latex>
                </b>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      );
    }
    return <TableHead />;
  };

  public tableBody = (type: string) => {
    if (type === 'horizontal' && this.props.data.rows) {
      return (
        <TableBody>
          {this.props.data.rows.map(row => (
            <TableRow>
              {row.map((column, i) => {
                if (i === 0) {
                  return (
                    <TableCell>
                      <b>
                        <Latex>{`${column}`}</Latex>
                      </b>
                    </TableCell>
                  );
                }
                return (
                  <TableCell>
                    <Latex>{`${column}`}</Latex>
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      );
    }
    if (type === 'vertical' && this.props.data.columns) {
      const transpose = this.props.data.columns[0].map((_, i) => (this.props.data.columns as string[][]).map(column => column[i]));
      return (
        <TableBody>
          {transpose.map(column => (
            <TableRow>
              {column.map(val => (
                <TableCell>
                  <Latex>{`${val}`}</Latex>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      );
    }
    return <TableBody />;
  };

  public latexTable = () => (
    <Table>
      {this.tableHeader()}
      {this.tableBody(this.props.data.type)}
    </Table>
  );

  public render() {
    return this.latexTable();
  }
}

export default LatexTable;
