import { getBackendUrl } from './getBackendUrl';

export const authProvider = {
  checkAuth: () => {
    return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
  },
  checkError: () => {
    return Promise.resolve();
  },
  getPermissions: () => {
    const role = localStorage.getItem('permissions');
    return role ? Promise.resolve(role) : Promise.reject();
  },
  login: ({ username, password }: { username: string; password: string }) => {
    const request = new Request(getBackendUrl(), {
      body: JSON.stringify({
        operationName: 'UserLogin',
        query:
          'mutation UserLogin($email: String!, $password: String!) {\n  login(email: $email, password: $password) {\n    hash\n    token\n    plan\n    subscription\n  type\n  autoUpgrade\n    __typename\n  }\n}',
        variables: {
          email: username,
          password,
        },
      }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
      method: 'POST',
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(response => {
        if (response.data.login.type !== 'admin' && response.data.login.type !== 'editor') {
          throw new Error('Invalid login');
        }
        localStorage.setItem('token', response.data.login.token);
        localStorage.setItem('permissions', response.data.login.type);
      });
  },
  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    return Promise.resolve();
  },
};
