import { opacify } from 'polished';
import React, { ComponentProps, FC } from 'react';
import styled, { css } from 'styled-components';
import { colors } from './colors';

interface Props {
  className?: string;
  onClick?: ComponentProps<typeof Container>['onClick'];
  onKeyPress?: (e: any) => void;
}

export const Card: FC<Props> = props => {
  return <Container {...props}>{props.children}</Container>;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.WHITE};
  border-radius: 30px;
  box-shadow: 1px 1px 6px ${opacify(-0.84, colors.BLACK)};
  height: min-content;
  width: min-content;
  border-radius: 30px;
  padding: 40px 32px;
  ${props =>
    props.onClick &&
    css`
      :hover {
        cursor: pointer;
        opacity: 0.8;
      }
    `}
`;
