import { opacify } from 'polished';
import styled, { css } from 'styled-components';
import { colors } from './colors';

export const Button = styled.button<{ disabled?: boolean }>`
  color: ${colors.WHITE};
  font-size: 14px;
  font-weight: 700;
  background-color: ${props => (props.disabled ? opacify(-0.25, colors.PINK) : colors.PINK)};
  padding: 8px 40px;
  border: 0px;
  border-radius: 30px;
  box-shadow: 1px 1px 6px ${opacify(-0.84, colors.BLACK)};
  :hover {
    ${props =>
      !props.disabled &&
      css`
        cursor: pointer;
        background-color: ${opacify(-0.25, colors.PINK)};
      `};
  }
  :focus {
    outline: 0;
  }
`;
