import gql from 'graphql-tag';
import { FetchType } from 'utils';

const OrganisationFields = gql`
  fragment AdminOrganisationField on Organisation {
    id
    name
    teacherTotal
    studentTotal
    homeworkTotal
    plan
    __typename
  }
`;

const OrganisationQuery = (fetchType: FetchType, builtQuery: any) => {
  switch (fetchType) {
    case 'GET_LIST':
    case 'GET_MANY':
      return {
        ...builtQuery,
        query: gql`
          query allOrganisations($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: OrganisationFilterInput) {
            items: allOrganisations(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {
              ...AdminOrganisationField
            }
            total: _allOrganisationsMeta(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {
              count
              __typename
            }
          }
          ${OrganisationFields}
        `,
      };
    case 'GET_ONE':
      return {
        ...builtQuery,
        query: gql`
          query Organisation($id: String) {
            data: Organisation(id: $id) {
              ...AdminOrganisationField
            }
          }
          ${OrganisationFields}
        `,
      };
    default:
      return builtQuery;
  }
};

export default OrganisationQuery;
