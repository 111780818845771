import gql from 'graphql-tag';
import { FetchType } from 'utils';

const UserFields = gql`
  fragment AdminUserFields on User {
    id
    uuid
    title
    email
    firstname
    lastname
    examboard
    type
    plan
    questionsLeft
    organisation {
      id
      name
    }
    __typename
    subscription
  }
`;

const UserQuery = (fetchType: FetchType, builtQuery: any) => {
  switch (fetchType) {
    case 'CREATE':
      return {
        ...builtQuery,
        query: gql`
          mutation createUser(
            $title: String
            $firstname: String!
            $lastname: String!
            $password: String
            $email: String!
            $examboard: Examboards!
            $type: UserTypes!
            $plan: String!
          ) {
            data: createUser(
              title: $title
              firstname: $firstname
              lastname: $lastname
              password: $password
              email: $email
              examboard: $examboard
              type: $type
              plan: $plan
            ) {
              ...AdminUserFields
            }
          }
          ${UserFields}
        `,
      };
    case 'GET_LIST':
      return {
        ...builtQuery,
        query: gql`
          query allUsers($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: UserFilterInput) {
            items: allUsers(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {
              ...AdminUserFields
            }
            total: _allUsersMeta(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder) {
              count
              __typename
            }
          }
          ${UserFields}
        `,
      };
    case 'GET_ONE':
      return {
        ...builtQuery,
        query: gql`
          query User($id: String) {
            data: User(id: $id) {
              ...AdminUserFields
            }
          }
          ${UserFields}
        `,
      };
    case 'UPDATE':
      return {
        ...builtQuery,
        query: gql`
          mutation updateUser(
            $id: String
            $firstname: String
            $lastname: String
            $email: String
            $examboard: Examboards
            $type: UserTypes
            $plan: UserPlans
          ) {
            data: updateUser(
              id: $id
              firstname: $firstname
              lastname: $lastname
              email: $email
              examboard: $examboard
              type: $type
              plan: $plan
            ) {
              ...AdminUserFields
            }
          }
          ${UserFields}
        `,
      };
    default:
      return builtQuery;
  }
};

export default UserQuery;
