import dynamic from 'next/dynamic';
import { opacify } from 'polished';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Card } from './Card';
import { Button } from './Button';
import { colors } from './colors';
import Latexify from './Latexify';

const Skeleton = dynamic(() => import('react-loading-skeleton'), { ssr: false });

interface Props {
  question?: {
    id: number | string;
    question: string;
    working: string;
    marks: number;
    expanded: boolean;
    rating: number;
    answers: {
      text: string;
      correct: boolean;
      selected: boolean;
      chosen: boolean;
    }[];
  };
  questionIndex: number;
  exam?: boolean;
  onClick?: (answerID: string, questionID: string, correct: boolean) => void;
  loading?: boolean;
}

export const ClassroomCard: FC<Props> = props => {
  return (
    <Container>
      <Header>
        {props.loading ? <Skeleton width="24px" height="24px" /> : <Latexify label={`${String.fromCharCode(props.questionIndex + 97)})`} />}
      </Header>
      <Question>
        {props.loading ? (
          [0, 1, 2, 3, 4].map(i => <Skeleton key={i} height="24px" />)
        ) : (
          <Latexify label={String(props.question?.question)} />
        )}
      </Question>
      <Marks>{props.loading ? <Skeleton width="24px" height="24px" /> : <Latexify label={`(${props.question?.marks})`} />}</Marks>
      <Answers>
        {props.loading ? (
          <div />
        ) : (
          props.question?.answers.map((answer, i) => (
            <Answer
              key={answer.text}
              disabled={!props.exam && answer.chosen}
              exam={props.exam}
              {...answer}
              onClick={() => props.onClick?.(`${i}`, `${props.question?.id}`, answer.correct)}
            >
              <Latexify label={answer.text} />
            </Answer>
          ))
        )}
      </Answers>
      {props.question?.expanded && (
        <Working>
          <Latexify label={String(props.question?.working)} />
        </Working>
      )}
    </Container>
  );
};

const Marks = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: -28px;
  z-index: 1;
`;
const Working = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 48px);
  padding: 24px;
  justify-content: center;
  margin-top: 24px;
`;

const Question = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 48px);
  padding: 24px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Container = styled(Card)`
  width: calc(100% - 100px);
  margin: 0px 50px 20px 50px;
  padding: 24px;
`;

const Answers = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`;

const Answer = styled(Button)<{ chosen?: boolean; selected?: boolean; correct?: boolean; exam?: boolean }>`
  color: ${colors.BLUE};
  background-color: ${colors.WHITE};
  border: 2px solid ${colors.DAMPENED_BLUE};
  margin: 8px 8px;
  box-shadow: none;
  :hover {
    cursor: ${props => (props.chosen ? 'default' : 'cursor')};
    background-color: ${props => (props.chosen ? colors.WHITE : opacify(-0.25, colors.DAMPENED_BLUE))};
  }
  ${props => {
    if (props.chosen && props.correct) {
      if (props.selected) {
        return css`
          background-color: ${props.exam ? colors.PASTEL_LIGHT_BLUE : colors.GREEN} !important;
        `;
      }
      return css`
        background-color: ${props.exam ? colors.WHITE : colors.GREEN} !important;
      `;
    }
    if (props.selected && !props.correct) {
      return css`
        background-color: ${props.exam ? colors.PASTEL_LIGHT_BLUE : colors.RED} !important;
      `;
    }
    return css``;
  }}
  text-transform: non !important;
`;
