import * as React from 'react';
import { Create, ReferenceInput, SelectInput, SimpleForm, TextInput } from 'react-admin';

export const create = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <TextInput source="firstname" />
      <TextInput source="lastname" />
      <TextInput source="email" />
      <TextInput source="password" />
      <SelectInput
        source="examboard"
        choices={[
          { id: 'AQA', name: 'AQA' },
          { id: 'EDEXCEL', name: 'EdExcel' },
          { id: 'OCR', name: 'OCR' },
        ]}
      />
      <SelectInput
        source="type"
        choices={[
          { id: 'student', name: 'Student' },
          { id: 'teacher', name: 'Teacher' },
          { id: 'admin', name: 'Admin' },
        ]}
      />
      <SelectInput
        source="plan"
        choices={[
          { id: 'basic', name: 'Basic' },
          { id: 'schoolBasic', name: 'School Basic' },
          { id: 'schoolPremium', name: 'School Premium' },
          { id: 'premiumMonthly', name: 'Premium Monthly' },
          { id: 'premiumYearly', name: 'Premium Yearly' },
          { id: 'premiumYearly2', name: 'Premium Yearly' },
          { id: 'premiumBiyearly', name: 'Premium Bi-yearly' },
          { id: 'premiumBiyearly2', name: 'Premium Bi-yearly' },
        ]}
      />
      <ReferenceInput label="Organisation" source="organisation.id" reference="Organisation">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
