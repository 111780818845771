import SchoolIcon from '@material-ui/icons/School';
import * as React from 'react';
import { Datagrid, List, TextField } from 'react-admin';

export const icon = SchoolIcon;
export const list = (props: any) => {
  return (
    <List {...props} bulkActionButtons={false}>
      <Datagrid>
        <TextField source="name" />
        <TextField source="teacherTotal" name="Teachers" />
        <TextField source="studentTotal" name="Students" />
        <TextField source="homeworkTotal" name="Homeworks" />
        <TextField source="plan" />
      </Datagrid>
    </List>
  );
};
